var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',{staticClass:"main-container"},[_c('section',{staticClass:"app-main"},[_c('div',{ref:"container",staticClass:"container",class:{
          container_full: _vm.isFullscreen,
        },attrs:{"id":"container"}},[_c('div',{staticClass:"main-background"},[(
              _vm.topic[_vm.activeKey]['choose']['type'] == 'check' ||
              _vm.topic[_vm.activeKey]['choose']['type'] == 'radio' ||
              _vm.topic[_vm.activeKey]['choose']['type'] == 'check_radio'
            )?_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.topic[_vm.activeKey]['bg_img']}}):_vm._e(),(_vm.topic[_vm.activeKey]['type'] == 'check_more')?_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.topic[_vm.activeKey]['choose']['chooseOption'][_vm.chooseActiveKey][
                'scene'
              ].length
                ? _vm.topic[_vm.activeKey]['choose']['chooseOption'][_vm.chooseActiveKey][
                    'scene'
                  ][_vm.sceneActiveKey]['bg_img']
                : _vm.topic[_vm.activeKey]['choose']['chooseOption'][_vm.chooseActiveKey][
                    'bg_img'
                  ]}}):_vm._e()]),_c('div',{staticClass:"title"},[_c('span',{staticStyle:{"font-size":"1.8rem"}},[_vm._v(_vm._s(_vm.question["name"] || "-"))]),_c('span',{staticClass:"ct"},[_vm._v("答题倒计时：")]),_c('span',[_vm._v(_vm._s(_vm.timeLable))])]),(
            _vm.topic[_vm.activeKey]['choose']['type'] == 'check' ||
            _vm.topic[_vm.activeKey]['choose']['type'] == 'radio'
          )?_c('radio',{attrs:{"topic":_vm.topic,"activeKey":_vm.activeKey,"percentage":_vm.percentage_method,"ckeckList":_vm.ckeckList,"radio":_vm.radio},on:{"update:ckeckList":function($event){_vm.ckeckList=$event},"update:ckeck-list":function($event){_vm.ckeckList=$event},"update:radio":function($event){_vm.radio=$event},"showKeyWords":_vm.showKeyWords,"audioChange":_vm.audioChange,"onInstall":_vm.onInstall,"onBall":_vm.onBall,"Rotate":_vm.Rotate,"unRotateClick":_vm.unRotateClick,"unBall":_vm.unBall,"unInstall":_vm.unInstall}}):_vm._e(),(_vm.topic[_vm.activeKey]['type'] == 'check_radio')?_c('checkRadio',{attrs:{"topic":_vm.topic,"percentage":_vm.percentage_method,"activeKey":_vm.activeKey,"radio":_vm.radio},on:{"update:radio":function($event){_vm.radio=$event}}}):_vm._e(),(_vm.topic[_vm.activeKey]['type'] == 'check_more')?_c('checkMore',{attrs:{"topic":_vm.topic,"chooseActiveKey":_vm.chooseActiveKey,"activeKey":_vm.activeKey,"percentage":_vm.percentage_method,"ckeckList":_vm.ckeckList,"sceneActiveKey":_vm.sceneActiveKey},on:{"update:ckeckList":function($event){_vm.ckeckList=$event},"update:ckeck-list":function($event){_vm.ckeckList=$event},"sceneChange":_vm.sceneChange,"sceneAns":_vm.sceneAns,"sceneNoneAns":_vm.sceneNoneAns}}):_vm._e(),_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"toolbar-content"},[_c('div',{staticClass:"btns"},[_c('div',{staticClass:"right-btn"},[(
                    _vm.topic[_vm.activeKey]['type'] == 'check_more' &&
                    _vm.topic[_vm.activeKey]['choose']['chooseOption'].length != 1
                  )?_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowNext),expression:"isShowNext"}],attrs:{"type":"primary"},on:{"click":_vm.next}},[_vm._v("下一步")]):_vm._e(),_c('el-button',{attrs:{"type":"primary","disabled":(_vm.sunmitDisabeld ||
                      (_vm.isShowNext &&
                        _vm.topic[_vm.activeKey]['type'] == 'check_more')) &&
                    _vm.topic[_vm.activeKey]['choose']['chooseOption'].length != 1},on:{"click":_vm.onSubmit}},[_vm._v("提交")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.reset}},[_vm._v("重做")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.goBack}},[_vm._v("返回")])],1)])])]),(_vm.isRotateShow)?_c('div',{staticStyle:{"top":"45.1383%","left":"35.9%","height":"6.31225%","width":"0.6%","position":"absolute","z-index":"2"}},[_c('div',{staticClass:"handles open",attrs:{"result":"","scence-idx":"0","index":"3"}})]):_vm._e(),(_vm.topic[_vm.activeKey]['keywords'])?_c('keyWords',{attrs:{"visible":_vm.modalType.keyVisible,"keywordOption":_vm.topic[_vm.activeKey]['keywords'],"close":() => (_vm.modalType.keyVisible = false)},on:{"keyChange":_vm.keyChange,"handDown":_vm.handDown}}):_vm._e(),_c('freBox',{attrs:{"visible":_vm.modalType.fireBoxVisible,"optionType":_vm.modalType.optionType,"close":() => (_vm.modalType.fireBoxVisible = false),"isCheck":_vm.modalType.isCheck},on:{"submit":_vm.freBoxSubmit}}),(_vm.topic[_vm.activeKey]['tableWords'])?_c('tableChart',{attrs:{"visible":_vm.modalType.tableVisible,"close":() => (_vm.modalType.tableVisible = false)}}):_vm._e(),_c('firepump',{attrs:{"visible":_vm.modalType.firepumpVisible,"optionLight":_vm.topic[_vm.activeKey]['light'],"close":() => (_vm.modalType.firepumpVisible = false)}}),_c('operaBus',{attrs:{"keywordOption":_vm.topic[_vm.activeKey]['keywords'],"close":() => (_vm.modalType.busxVisible = false),"visible":_vm.modalType.busxVisible,"disabled":_vm.operaBusDis,"operaBusFn":() => {
              _vm.topic[_vm.activeKey]['operaBusFn'] &&
                _vm.topic[_vm.activeKey]['operaBusFn']();
            }}}),_c('enddevice',{attrs:{"visible":_vm.modalType.enddeviceVisible}}),(!_vm.isFullscreen)?_c('div',{staticClass:"fullscreen",on:{"click":_vm.fullscreenClick}},[_vm._v(" 进入全屏 ")]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }